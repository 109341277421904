import { AuthHeader, axiosInstance, setHeader } from "../utility/AxoisConfig";

export const getDetails = async (token) => {
	let headers=setHeader(token)
	let res = await axiosInstance.get("/api/home-details",{headers});
	return res;
};

export const getLogedInUserDetails = async (token) => {
	let headers=setHeader(token)
	let res = await axiosInstance.get("/api/home-details-load-more",{headers});
	return res;
};

export const getCurations = async (token,page=1) => {
	let headers=setHeader(token)
	const res = await axiosInstance.get(`api/curation/`,{params:{page:page,page_size:5},
		headers: headers,
	});
	// console.log(res);
	return res;
};