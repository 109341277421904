import { List, Paper, Stack } from "@mui/material";


import { PlayoffCircle } from "./Playoff";

export const PlayoffList = ({ playoffList }) => {
  return (
    <List
      direction="row"
      component={Stack}
      spacing={2}
  
      sx={{
        overflowX: "scroll",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          width: 0,
        },
      }}
    >
      {playoffList.map((item,i) => (
        <PlayoffCircle playoff={item} key={`playoff-circle-${i}`} />
      ))}
    </List>
    //   </ImageList>
  );
};
