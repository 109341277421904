import { Box, Typography } from "@mui/material";
import React from "react";

export default function CompanyNameFooter({ required = false }) {
	return required ? (
		<Box
			p={2}
			sx={{
				textAlign: "center",
				background: "white",
				// color: "#fff",
			}}
		>
			{/* <Divider /> */}

			<Typography
				variant="smallText"
				component={"p"}
				sx={{ textTransform: "capitalize" }}
			>
				venetto technologies private limited
			</Typography>
		</Box>
	) : null;
}
