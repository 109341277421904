import {
	Avatar,
	ImageListItem,
	ImageListItemBar,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Image from "next/image";
import { useRouter } from "next/router";

export const PlayoffCircle = ({ playoff }) => {
	let router = useRouter();
	const handleClick = () => {
		router.push(`/playoff/${playoff.slug}/curations`);
	};
	return (
		<Box onClick={handleClick} sx={{ width: "100px" }}>
			<ImageListItem sx={{ borderRadius: "50%" }} key={playoff.img}>
				<Avatar sx={{ width: 98, height: 98 }}>
					<Image
						src={playoff.image}
						alt={playoff.title}
						width={98}
						height={98}
					/>
				</Avatar>
			</ImageListItem>
			<Box
				pt={1}
				sx={{
					textAlign: "center",
					overflow: "hidden",
					textOverflow: "ellipsis",
					display: "-webkit-box",
					WebkitLineClamp: "2",
					WebkitBoxOrient: "vertical",
					fontSize: "14px",
				}}
			>
				<Typography variant="body2" title={playoff.title}>
					{playoff.title}
				</Typography>
			</Box>
		</Box>
	);
};
