import { Card, CardContent, Grid, Skeleton, Stack } from "@mui/material";
import React from "react";
import { cardStyle } from "../../../styles/card";

function GrapeListSkeleton() {
  const style = cardStyle;
  return (
    <Card
      sx={{
        ...style.card,
        marginBottom: 2,
        boxShadow: "0px 4px 10px -6px rgba(0, 0, 0, 0.12)",
      }}
    >
      <CardContent>
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        <Grid
          container
          columnSpacing={"1px"}
          borderRadius={2}
          sx={{ overflow: "hidden", position: "relative" }}
        >
          <Grid item xs={3}>
            <Skeleton variant="rounded" height={120} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rounded" height={120} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rounded" height={120} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rounded" height={120} />
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1.5} mt={1}>
          <Skeleton
            animation="wave"
            variant="circular"
            width={25}
            height={25}
          />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"50%"} />
        </Stack>
      </CardContent>
    </Card>
  );
}

export default GrapeListSkeleton;
